@font-face {
    font-family: 'F5.6';
    src: url('../public/fonts/F5.6-Regular.woff') format('woff');
}

*{
  overscroll-behavior: none;
}
body {
  margin: 0;
  /* font-family: 'F5.6', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #cccccc;
  width:100vw;
  height:100vh;
  color:rgba(0,0,0,0.87);
  overscroll-behavior: none;
}

